<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nueva</span>
                <span v-if="!isNew">Editar</span>
                categoría de incidencia
            </div>

            <v-form
                ref="form"
                v-model="isValid"
                autocomplete="off">
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
                <v-tabs-slider color="blue"></v-tabs-slider>

                <v-tab href="#tab-cat">Categoría</v-tab>
                <v-tab href="#tab-emp">Empresas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item :value="'tab-cat'">
                    <v-card style="background-color: #F5F5F5" flat>
                      <!-- FORMULARIO CATEGORIA -->
                      <v-row>
                          <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                              <v-text-field
                                  class="mb-n7"
                                  outlined
                                  dense
                                  text
                                  v-model="incCat.IncCatCod"
                                  label="Código">
                              </v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                              <v-text-field
                                  class="mb-n7"
                                  outlined
                                  dense
                                  text
                                  v-model="incCat.IncCatNom"
                                  required
                                  :rules="[v => !!v || 'El nombre es obligatorio']"
                                  label="Nombre *">
                              </v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                              <v-text-field
                                  class="mb-n7"
                                  outlined
                                  dense
                                  text
                                  :value="incCat.IncCatParent != null ? incCat.IncCatParent.IncCatNom : ''"
                                  tabindex="-1"
                                  readonly
                                  label="Tipo">
                              </v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                              <PerFind class="mb-n7" ref="perDepCtrl" v-model="incCat.PerDepRes" :perCatIdAnyLevel="103" label="Departamento responsable"></PerFind>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                              <PerFind class="mb-n7" ref="perDirCtrl" v-model="incCat.PerDirRes" :perCatIdAnyLevel="101" label="Director responsable"></PerFind>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="5">
                              <v-textarea
                                  class="mt-0 mb-n7"
                                  outlined
                                  auto-grow
                                  v-model="incCat.IncCatObs"
                                  label="Observaciones">
                              </v-textarea>
                          </v-col>
                      </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-emp'">
                  <v-card style="background-color: #F5F5F5" flat>
                    <v-row class="mt-0">
                      <!-- EMPRESAS -->
                      <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Boton añadir operación -->
                        <v-btn
                        :loading="loading"
                        :disabled="editNewIncCatEmp"
                        @click="onBtnNuevaIncCatEmp"
                        color="primary"
                        small
                        class="mb-2">
                          Añadir empresa
                        </v-btn>

                        <v-data-table
                          :headers="incCatEmpHeaders"
                          :items="incCatEmpDataRows"
                          :options.sync="incCatEmpOptions"
                          :footer-props="{itemsPerPageOptions: [100]}"
                          :server-items-length="incCatEmpTotalDataRows"
                          :loading="incCatEmpLoading"
                          style="overflow: scroll"
                          mobile-breakpoint="0"
                          class="elevation-1 perOpeDt"
                          dense
                          >
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.IncCatMail="{ item }">
                                <v-text-field class="my-1" outlined dense text
                                  v-model="item.IncCatMail"
                                  label="Mail"
                                  :hide-details="true"
                                ></v-text-field>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.Opt="{ item }">
                              <v-btn
                                color="error"
                                class="mr-2 my-1"
                                small
                                @click.stop="onBtnShowIncCatEmpDeleteModal(item)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <!-- NUEVA OPERACION FORMULARIO -->
                            <template v-if="editNewIncCatEmp" slot="body.append">
                              <tr class="new-row">
                                  <td style="min-width: 350px">
                                    <EmpFind class="mb-n7" ref="newIncCatEmpEmpCtrl" v-model="newIncCatEmp.Emp" :empToHide="incCatEmpDataRows" label="Empresa"></EmpFind>
                                  </td>
                                  <td style="min-width: 210px">
                                    <v-text-field class="mb-n1" outlined dense text
                                      v-model="newIncCatEmp.IncCatMail"
                                      label="Mail"
                                      :hide-details="true"
                                    ></v-text-field>
                                  </td>
                                  <td style="min-width: 230px" align="right">
                                    <v-btn
                                      :loading="incCatEmpLoading"
                                      @click="onBtnShowSaveIncCatEmp"
                                      :disabled="!isValidIncCatEmp(newIncCatEmp)"
                                      color="primary"
                                      class="mt-2 mb-4 mr-2"
                                      small
                                    >
                                      Aceptar
                                    </v-btn>
                                      
                                    <v-btn
                                      @click="onBtnCancelIncCatEmp"
                                      class="mt-2 mb-4 mr-2"
                                      small
                                    >
                                      Cancelar
                                    </v-btn>
                                  </td>
                              </tr>
                            </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
            </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px">
                    Aceptar
                </v-btn>
                
                <v-btn
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                    @click="goToList">
                    Cancelar
                </v-btn>
                
                <v-btn v-if="!isNew && canDelete"
                    color="error"
                    class="mt-2 mb-4"
                    style="width:100px"
                    @click="onBtnShowDeleteModal()">
                    Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar categoría -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORÍA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar empresa -->
        <v-dialog
            v-model="showIncCatEmpDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR EMPRESA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la empresa?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="incCatEmpLoading"
                        color="primary"
                        @click="onBtnIncCatEmpDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelIncCatEmpDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpFind from "../components/EmpFind.vue";
import PerFind from "../components/PerFind.vue";
export default ({
    components: {
        EmpFind, PerFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz']),
        canDelete: function () {
            const mantenimientoId = 1;
            if (this.incCat.IncCatId == mantenimientoId) { return false; }
            if (this.incCat.IncCatParent == null) { return true; }
            return this.incCat.IncCatParent.IncCatId != mantenimientoId; // No es mantenimiento
        }
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showIncCatEmpDeleteDialog: false,
            incCat: {
                IncCatId: 0,
                IncCatCod: '',
                IncCatNom: '',
                IncCatParent: null,
                IncCatIdParent: null,
                IncCatObs: '',
                PerDepRes: null,
                PerDirRes: null
            },
            // Tabla Empresas
            incCatEmpLoading: false,
            incCatEmpTotalDataRows: 0,
            incCatEmpDataRows: [],
            incCatEmpOptions: {itemsPerPage: 100},
            incCatEmpHeaders: [
                { text: 'Empresa', width:325, sortable: false, value: 'Emp.EmpNom' },
                { text: 'Mail', width:250, sortable: false, value: 'IncCatMail' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición empresas
            editNewIncCatEmp: false, // Indica si se esta editando la nueva empresa
            newIncCatEmp: {}, // Datos de la nueva empresa
            selectedIncCatEmp: null, // Empresa seleccionada para borrar
        }
    },
    // watch: {
    //   incCat: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.incCat.IncCatId = this.$route.params.id;
        this.loadData();
      } else {
        // Init IncCatParent
        if(this.$store.state.IncCatParentData != null) {
          this.incCat.IncCatParent = this.$store.state.IncCatParentData;
          this.incCat.IncCatIdParent = this.$store.state.IncCatParentData.IncCatId;
        }

        // Carga de empresas por defecto
        this.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/categorias-incidencia');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_INCCAT',
            IncCatId: this.incCat.IncCatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inccat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.IncCat;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la categoría.');
                this.goToList();
            }
          });
        })
      },
      getDefaultEmpsFromApi() {
        this.empLoading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DEFAULT_EMP_LIST',
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/emp", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data;

            setTimeout(() => {
                this.empLoading = false;
                resolve({
                  items
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.empLoading = false;
                alert('Error al obtener las empresas por defecto.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.incCat.PerIdDepRes = this.incCat.PerDepRes != null ? this.incCat.PerDepRes.PerId : null;
        this.incCat.PerIdDirRes = this.incCat.PerDirRes != null ? this.incCat.PerDirRes.PerId : null;
        const incEmps = this.incCatEmpDataRows.map(m => { return { EmpId: m.EmpId, IncCatMail: m.IncCatMail } });

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            IncCat: this.incCat,
            IncCatEmps: incEmps
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inccat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la categoría.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            IncCatId: this.incCat.IncCatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inccat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la categoría.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Categoría no encontrada.');
              this.goToList();
              return;  
            }

            this.incCat = data.item;
            
            this.incCatEmpTotalDataRows = data.item.IncCatEmp.length;
            this.incCatEmpDataRows = [];
            data.item.IncCatEmp.forEach(i => {
              this.incCatEmpDataRows.push({
                EmpId: i.EmpId,
                Emp: {
                  EmpId: i.Emp.EmpId,
                  EmpNom: i.Emp.EmpNom
                },
                IncCatMail: i.IncCatMail
              });
            });
          });
      },
      loadDefaultEmps () {
        this.getDefaultEmpsFromApi()
          .then(data => {
            const newEmpTotal = data.items.length;
            let newEmpList = [];
            data.items.forEach(i => {
              newEmpList.push({
                EmpId: i.EmpId,
                Emp: {
                  EmpId: i.EmpId,
                  EmpNom: i.EmpNom
                },
                IncCatMail: ''
              });
            });
            this.incCatEmpTotalDataRows = newEmpTotal;
            this.incCatEmpDataRows = newEmpList;
          });
      },
      //#endregion

      //#region  Añadir
      onBtnNuevaIncCatEmp() {
        this.editNewIncCatEmp = true;
        this.newIncCatEmp = { IncCatMail: '', Emp: null };
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
        this.validate();
        if(!this.isValid) { return; }
        this.postDataToApi().then(data => {
          if(data.item.IncCatId != null) {
              this.goToList();
          }
          else {
              alert('Error al guardar la categoría.');
          }
        });
      },
      onBtnShowSaveIncCatEmp() {
        if(!this.isValidIncCatEmp(this.newIncCatEmp)) { return; }
        
        this.newIncCatEmp.EmpId = this.newIncCatEmp.Emp.EmpId;
        this.incCatEmpDataRows.push(this.newIncCatEmp);
        this.incCatEmpTotalDataRows += 1;
        
        this.newIncCatEmp = {};
        this.editNewIncCatEmp = false;
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        if(!this.canDelete) { return; }
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
              this.showDeleteDialog = false;
              this.goToList();
          }
          else {
              alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Eliminar empresas
      removeIncCatEmp(item) {
        var index = this.incCatEmpDataRows.indexOf(item);
        if (index != -1) {
          this.incCatEmpDataRows.splice(index, 1);
          this.incCatEmpTotalDataRows -= 1;
        }
      },
      onBtnIncCatEmpDelete() {
        this.removeIncCatEmp(this.selectedIncCatEmp);
        this.selectedIncCatEmp = null;
        this.showIncCatEmpDeleteDialog = false;
      },
      //#endregion


      //#region Cancelar creacion
      onBtnCancelIncCatEmp() {
        this.editNewIncCatEmp = false;
        this.newIncCatEmp = {};
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowIncCatEmpDeleteModal(item) {
        this.selectedIncCatEmp = item;
        this.showIncCatEmpDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelIncCatEmpDelete() {
        this.selectedIncCatEmp = null;
        this.showIncCatEmpDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      },
      isValidIncCatEmp(incCatEmp) {
        if(incCatEmp == null) { return false; }
        if(incCatEmp.Emp == null) { return false; }
        
        return true;
      },
      //#endregion
    } 
})
</script>
